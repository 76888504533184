@use '@styles/mixins/breakpoints' as breakpoints;

.wrapper {
  display: flex;
  background-color: var(--black);
  color: var(--white);
  width: 100%;
  text-align: center;
  height: 60px;

  @include breakpoints.up(sm) {
    height: 40px;
  }
}

.content {
  display: flex;
  height: inherit;
  width: inherit;
  padding: 0 16px;
}

.text-content {
  width: auto;

  & a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}

.text-link-container {
  display: flex;
  width: 100vw;
  max-width: 100%;
  flex-direction: row;
  height: inherit;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  justify-content: center;
  gap: 8px;

  @include breakpoints.up(sm) {
    width: auto;
  }
}

// NOTE: dusplicated class name to increase specificity and prevent conflicts
.text-link.text-link {
  font-size: 0.75rem;
  border-color: var(--white);
  padding: 0.25rem 1rem;
  margin: 0;
  height: auto;
  min-width: 2rem;
  font-weight: 400;
  width: fit-content;

  &:hover {
    background-color: var(--grey);
    border-color: var(--white);
  }

  @include breakpoints.up(sm) {
    white-space: nowrap;
  }
}

.button-container {
  font-size: 0.75rem;
  font-weight: 700;
  background-color: transparent;
  border-color: var(--white);
  border-radius: 4px;
  border-width: 1px;
  color: var(--white);
  padding: 4px;
  min-width: 7rem;
  margin: 8px;

  @include breakpoints.up(sm) {
    padding: 4px 12px;
  }

  &:hover {
    background-color: var(--grey);
    border-color: var(--white);
  }
}
